import { jwtDecode } from "jwt-decode";

const isTokenExpiredHook = (token) => {
  if (!token) {
    return true; // No token, treat it as expired
  }

  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Convert to seconds

    // Check expiration time from token (exp field)
    return decodedToken.exp < currentTime;
  } catch (error) {
    return true; // Any decoding error, treat it as expired
  }
};

export default isTokenExpiredHook;
