import React, { useEffect, useState } from "react";
import { Box, TextField, Typography, Divider, useTheme } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import ReusableButton from "./ReusableButton";
import { Axiosclient } from "configs/axiosConfig";
import SSRStorage from "configs/storage";
import { encrypt } from "helpers/encryption";

// Env Variables
const RESOURCES_APP_LINK = process.env.GATSBY_RESOURCES_APP_LINK;

const storage = new SSRStorage();

const LoginComponent = () => {
  const [showCodePrompt, setShowCodePrompt] = useState(false);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resendCode, setResendCode] = useState(false);
  const [timer, setTimer] = useState(20);

  const theme = useTheme();

  // countdown timer for code resend
  useEffect(() => {
    let interval;
    if (showCodePrompt && !resendCode) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(interval);
            setResendCode(true);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [showCodePrompt, resendCode]);

  useEffect(() => {
    if (error) {
      setShowError(true);
    } else {
      const timeoutId = setTimeout(() => setShowError(false), 300);
      return () => clearTimeout(timeoutId);
    }
  }, [error]);

  // switch between email and code input
  const switchToCodeInput = () => {
    setShowCodePrompt(true);
    setTimer(20); // Reset the timer
    setResendCode(false); // Reset resend code state
    setCode("");
  };

  const switchToEmailInput = () => {
    setShowCodePrompt(false);
    setError("");
  };

  // handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    // validate email or code depending on showCodePrompt
    if (!showCodePrompt) {
      const emailError = validateEmail();
      if (emailError) {
        setError(emailError);
      } else {
        // Handle email login
        Axiosclient.post("auth/login", {
          email: email,
        })
          .then((/*data*/) => {
            // switch view to code Input on success
            switchToCodeInput();
          })
          .catch((err) => {
            setError(err.message || "An error occurred signing in.");
          });
      }
      setLoading(false);
      return;
    }

    const codeError = validateCode();
    if (codeError) {
      setError(codeError);
    } else {
      Axiosclient.post("auth/verify", {
        email: email,
        code: code,
      })
        .then((data) => {
          // Handle successful code validation
          if (data?.data?.access_token) {
            // Redirect user with token and save to local storage
            localStorage.setItem("token", JSON.stringify(data.data));

            // Calculate the expiration time (24 hours from now)
            storage.setItem("user_token", data?.data?.access_token, {
              path: "/",
              expires: new Date(Date.now() + 86400000),
            });
            // Set encrypted user email to cookies
            storage.setItem("user_email", encrypt(email), { path: "/", expires: new Date(Date.now() + 86400000) });
            storage.setItem("logged_out", false, { path: "/", expires: new Date(Date.now() + 86400000) });
            window.location.href = RESOURCES_APP_LINK;
          }
        })
        .catch((err) => {
          setError(err?.response?.data?.error_description || "Wrong email or verification code");
        });
    }
    setLoading(false);
  };

  // validate email field
  const validateEmail = () => {
    const regexEmail = /\S+@\S+\.\S+/;
    if (!email || email === "") return "Email is required.";
    else if (!regexEmail.test(email)) return "Please enter a valid email.";
    return "";
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    // check if email is valid and clear error
    const emailError = validateEmail();
    if (!emailError) setError("");
  };

  // validate code field
  const validateCode = () => {
    if (!code || code === "") return "Code is required.";
    return "";
  };

  // on resend code link click
  const handleResendCode = () => {
    Axiosclient.post("auth/login", {
      email: email,
    })
      .then(() => {
        setResendCode(false);
        setTimer(20);
      })
      .catch((err) => {
        setError(err.message || "An error occurred signing in.");
      });
  };

  return (
    <Box>
      {/* back button */}
      {showCodePrompt && (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            position: "absolute",
            top: "20px",
            left: "20px",
            cursor: "pointer",
            transition: "all .2s ease-in-out",
            "&:hover": {
              transform: "scale(1.01)",
              color: "#2C85FF",
            },
          }}
          onClick={switchToEmailInput}
        >
          <ArrowBack />
          <ReusableButton variant="tertiary" title="Back" />
        </Box>
      )}

      {/* Title */}
      <Typography
        variant="titleExtraBold"
        fontSize={{ xs: "28px", sm: "32px", md: "40px" }}
        textAlign={{ xs: "center", md: "left" }}
      >
        Sign In With Email
      </Typography>
      <br />
      {/* Description */}
      <Typography
        variant="description"
        color={theme.palette.text.description2}
        mt={2}
        textAlign={{ xs: "center", md: "left" }}
      >
        {showCodePrompt ? (
          <>
            {"Enter the code we sent to "}
            <strong>{email}</strong>
            {" to log in."}
          </>
        ) : (
          "We will check if you have an account."
        )}
      </Typography>
      {/* Form */}
      <Box
        component={"form"}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        sx={{
          marginTop: "40px",
        }}
      >
        {/* text field for email / code depending on showCodePrompt */}
        <TextField
          fullWidth
          id={showCodePrompt ? "code" : "email"}
          name={showCodePrompt ? "code" : "email"}
          placeholder={showCodePrompt ? "Code" : "E-mail"}
          value={showCodePrompt ? code : email}
          onChange={(e) => (showCodePrompt ? setCode(e.target.value) : handleEmailChange(e))}
          required
          sx={{
            bgcolor: "#F6F9FF",
            "& .MuiOutlinedInput-root": {
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            },
            borderRadius: "5px",
          }}
        />

        {/* Error message display */}
        <Box
          sx={{
            opacity: showError ? 1 : 0,
            transition: "opacity 300ms ease-in-out",
            height: "24px",
            overflow: "hidden",
            my: 1,
          }}
        >
          {error && (
            <Typography variant="body1" sx={{ color: theme.palette.text.error }}>
              {error}
            </Typography>
          )}
        </Box>
        {/* Submit button */}
        <ReusableButton
          type="submit"
          fullWidth
          title={loading ? "Please Wait..." : showCodePrompt ? "Continue" : "Sign In"}
          disabled={!email || (showCodePrompt && !code) || loading}
        />

        {/* bottom of form section */}
        {showCodePrompt ? (
          // Resend code link section
          <Box
            display={"flex"}
            sx={{
              flexDirection: { xs: "column", sm: "row" },
            }}
            gap={1}
            justifyContent={"center"}
            alignItems={"center"}
            mt={2}
          >
            <Typography variant="caption2">Didn’t get the code?</Typography>
            {resendCode ? (
              <ReusableButton variant="tertiary" title="Resend Code" onClick={handleResendCode} />
            ) : (
              <Typography variant="caption2" color={theme.palette.text.caption}>
                Resend in {timer} second{timer > 1 ? "s" : ""}
              </Typography>
            )}
          </Box>
        ) : (
          // Request a free trial section
          <Box>
            <Divider
              sx={{
                mt: 7,
                mb: 8,
              }}
            />
            <Box display={"flex"} flexDirection={"column"} gap={3} justifyContent={"center"} alignItems={"center"}>
              <Typography variant="caption2">Don&apos;t have an account?</Typography>
              <ReusableButton fullWidth title={"Book a demo"} variant={"secondary"} link={"/demo"} />
              <Typography variant="description" color={theme.palette.text.caption} mt={2} textAlign={"center"}>
                Streamline Salesforce Integration Effortlessly: Your Path to Simplified Success.
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default LoginComponent;
