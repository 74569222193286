import CryptoJS from "crypto-js";
import { EncryptionSecretKey } from "./app-environments";

export const encrypt = (plainText) => {
  const cipherText = CryptoJS.AES.encrypt(plainText, EncryptionSecretKey).toString();
  return cipherText;
};

export const decrypt = (cipherText) => {
  const bytes = CryptoJS.AES.decrypt(cipherText, EncryptionSecretKey);
  const plainText = bytes.toString(CryptoJS.enc.Utf8);
  return plainText;
};
