import axios from "axios";

// axios.defaults.baseURL = 'http://api.appnigma.ai/' s
export const Axiosclient = axios.create({
  baseURL: "https://api.appnigma.ai/",
});

axios.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // console.log('moved to errored', error)

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
