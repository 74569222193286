import React, { useCallback, useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Main } from "layouts";
import Container from "components/Container";
import imageSrc from "images/LeftScreen.webp";
import bgUrl from "images/hero-header/staticBackground.svg";
import LoginComponent from "components/LoginComponent";
import SSRStorage from "configs/storage";
import isTokenExpiredHook from "hooks/IsTokenExpiredHook";

const storage = new SSRStorage();
const RESOURCES_APP_LINK = process.env.GATSBY_RESOURCES_APP_LINK;

const BookDemo = () => {
  const [loading, setLoading] = useState(true);
  const [redirecting, setRedirecting] = useState(false);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const checkRender = useCallback(async () => {
    const logged_out = await storage.getItem("logged_out");

    // Check for logout flag from session
    if (logged_out) {
      await storage.removeItem("user_token");
      await storage.removeItem("logged_out");
      setLoading(false);
      return;
    }

    const token = await storage.getItem("user_token");

    // check if token exists and is not expired
    if (token && !isTokenExpiredHook(token)) {
      setRedirecting(true);
      window.location.href = RESOURCES_APP_LINK;
      return;
    }
  }, []);

  useEffect(() => {
    // Check if user is already logged in
    checkRender().then(() => {
      if (!redirecting) {
        setLoading(false);
      }
    });
  }, [checkRender, redirecting]);

  if (loading || redirecting) {
    return null;
  }

  return (
    <Main>
      <Box sx={{ backgroundImage: `url(${bgUrl})`, width: "100%" }}>
        <Container pt={10}>
          <Grid container spacing={0}>
            {isMd && (
              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  bgcolor: "#F6F9FF",
                  borderRadius: "30px 0 0 30px",
                  overflow: "hidden",
                }}
              >
                <Box
                  component="img"
                  height="100%"
                  width="100%"
                  sx={{
                    maxHeight: "100%",
                    objectFit: "cover",
                    marginLeft: "-50px",
                    transform: "scale(1.2)",
                  }}
                  src={imageSrc}
                  alt="Appnigma ai login"
                  loading="lazy"
                />
              </Grid>
            )}
            <Grid item xs={12} md={6} bgcolor={"white"} borderRadius={isMd ? "0 30px 30px 0" : "30px"}>
              <Box
                sx={{
                  padding: { xs: "60px 20px", md: "80px 46px" },
                  position: "relative",
                }}
                alignItems="center"
              >
                <LoginComponent />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Main>
  );
};

export default BookDemo;
